import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'

import enLocale from './en'
import chLocale from './ch'

Vue.use(VueI18n)

const messages = {
    en: {
      ...enLocale,
      // ...elementEnLocale
    },
    ch: {
      ...chLocale,
      // ...elementZhLocale
    }
  }


  const i18n = new VueI18n({
    locale: window.sessionStorage.getItem('language') || 'ch', //放入cookie缓存
    messages, // set locale messages
    silentTranslationWarn: true
  })
  
  
  export default i18n