import axios from "@/http/api"


const get = (url, data) => {
    return request(url, 'GET', data);
}

const post = (url, data) => {
    return request(url, 'POST', data);
}

const put = (url, data) => {
    return request(url, 'PUT', data);
}

const del = (url, data) => {
    return request(url, 'DEL', data);
}


const maximum_backoff = 64 * 1000
const retry = (url, data, times, deadline) => {
    const operationTimeout = [];
    for (let i = 0; i < times; i++) {
        if (deadline < 0) {
            break;
        }
        const timeout = createTimeout(i, maximum_backoff);
        deadline -= timeout;
        operationTimeout.push(timeout);
    }

    times = operationTimeout.length;
    return new Promise((resolve, reject) => {
        const temp = () => {
            get(url, data).then(resolve).catch(err => {
                if (times === 0) {
                    return reject(err)
                }
                times--
                setTimeout(temp, operationTimeout.shift())
            })
        }
        temp()
    })
}

const createTimeout = (times, maximum_backoff) => {
    const random_number_milliseconds = Math.floor(Math.random() * 1000);
    return Math.min(Math.pow(2, times) * 1000 + random_number_milliseconds, maximum_backoff);
}

const request = (url, method, data) => {
    if (method && method === "GET") {
        return axios({
            url: url,
            method: method,
            params: data
        })
    }
    return axios({
        url: url,
        method: method,
        data: data || {}
    })
};

const mimeMap = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip: 'application/zip'
}

const download = (url, data, type) => {
    return axios({
        url: url,
        method: "POST",
        data: data,
        responseType: "blob"
    }).then(res => {
        console.log(res)
        let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([res.data], {type: mimeMap[type ? type : 'xlsx']}));
        link.target = "_blank";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href); // 释放URL 对象
        document.body.removeChild(link);
    }).catch(() => {
        useMessage.error('文件下载失败')
    })
};

const upload = (url, data) => {
    return axios({
        url: url,
        method: "POST",
        data: data,
        headers: { "Content-Type": "multipart/form-data" }
    })
};

const dict = (data) => {
    return axios({
        url: url.DictData.listDictData,
        method: "GET",
        params: {'dictType': data}
    })
};

export default {
    get,
    post,
    put,
    del,
    dict,
    download,
    upload
}
