<template>
  <div :class="['top']">
    <div class="img-box">
      <img :src="navData.navigationImg" />
    </div>
    <div class="alt-text-box">
      <div class="alt-text">
        <h3>{{ navData.navigationName }}</h3>
        <p>{{ navData.navigationDescribe }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    navData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
