<template>
  <div id="app">
    <div class="app-overflow">
      <div id="nav">
        <router-link to="/"></router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

// 隐藏滚动条
// html {
//     overflow: -moz-hidden-unscrollable;
//     height: 100%;
// }

// body::-webkit-scrollbar {
//     display: none !important;
// }

// body {
//     -ms-overflow-style: none;
//     height:100%;
// 	  width: calc(100vw + 18px);
// 	  overflow: auto;
//     /* 隐藏浏览器默认的滚动条 */
// 	  -ms-overflow-style: none;
//   	/* IE 10+ */
// 	  overflow: -moz-scrollbars-none;
// 	  /* Firefox */
// 	  scrollbar-width: none;
// 	  /*firefox*/
// }
// .app-overflow{
//   overflow-x: hidden;
//   overflow-y: scroll;
// }

body,
html {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

/*包含以下五种的链接*/
a {
  color: #666;
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  color: #666;
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  color: #666;
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  color: #666;
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  color: #666;
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  color: #666;
  text-decoration: none;
}
</style>
