import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: (resolve) => require(['../views/Home.vue'], resolve),
    children: [
      {
        path: '/',
        name: 'homePage',
        alias: '/homePage',
        // component:()=>import("../components/homePage/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/homePage/index.vue'], resolve)
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        //  component:()=>import("../components/aboutUs/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/aboutUs/index.vue'], resolve)
      },
      {
        path: '/IndustrySolutions',
        name: 'IndustrySolutions',
        // component:()=>import("../components/IndustrySolutions/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/IndustrySolutions/index.vue'], resolve)
      },
      {
        path: '/IndustrySolutions/:id',
        name: 'IndustrySolutionsDetail',
        // component:()=>import("../components/IndustrySolutions/index.vue")
        //路由懒加载
        component: (resolve) =>
          require([
            '../components/IndustrySolutions/lib/IndustrySolutionsDetail.vue'
          ], resolve)
      },
      {
        path: '/newsCenter',
        name: 'newsCenter',
        // component:()=>import("../components/newsCenter/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/newsCenter/index.vue'], resolve)
      },
      {
        path: '/newsCenter/:id',
        name: 'newsDetail',
        // component:()=>import("../components/newsCenter/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/newsCenter/lib/newsDetail.vue'], resolve)
      },
      {
        path: '/productCenter',
        name: 'productCenter',
        // component:()=>import("../components/productCenter/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/productCenter/index.vue'], resolve)
      },
      {
        path: '/productDetail/:id',
        name: 'productDetail',
        component: (resolve) =>
          require([
            '../components/productCenter/lib/ProductDetail.vue'
          ], resolve)
      },
      {
        path: '/recruit',
        name: 'recruit',
        // component:()=>import("../components/recruit/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/recruit/index.vue'], resolve)
      },
      {
        path: '/service',
        name: 'service',
        // component:()=>import("../components/service/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/service/index.vue'], resolve)
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        // component:()=>import("../components/service/index.vue")
        //路由懒加载
        component: (resolve) =>
          require(['../components/joinUs/index.vue'], resolve)
      }
    ]
  }
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  // mode:'hash ',
  routes
})

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router
