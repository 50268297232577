import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './http/index'

import VueLazyLoad from 'vue-lazyload' // 懒加载
Vue.use(VueLazyLoad, {
  //error:require('../src/assets/img/swp2.png'), // 加载错误的图片
  //loading:require('../src/assets/img/swp1.png') // 加载时的图片
})

// 导入插件
import '../src/plugin'

// 一些方法
import utils from '@/js/utils'
Vue.prototype.$utils = utils

// 全局事件
Vue.prototype.$eventBus = new Vue()

Vue.prototype.$axios = axios

// 项目做国际化
import i18n from './lang'

Vue.config.productionTip = false

import '@/components/common/index'

// 允许以0开头，允许存在小数点
Vue.prototype.validForbid = function (value, number = 255) {
  value = value
    .replace(
      /[`~!@#$%^&*()\+=<>?:"{}|，/;'\\[\]·~！@#￥%……&*（）\+={}|《》？：“”【】、，,；‘’。、,|^|]/g,
      ''
    )
    .replace(/\s/g, '')
  if (value.length >= number) {
    this.$message({
      type: 'warning',
      message: `输入内容不能超过${number}个字符`
    })
  }
  return value
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
