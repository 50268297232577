export default {
  Home: 'Home',
  productCenter: 'Product Center',
  IndustrySolutions: 'SCENAEISO',
  newsCenter: 'News',
  service: 'Service',
  aboutUs: 'About Us',
  joinUs: 'Join Us',
  MessageBoard: 'MESSAGE',
  thankMsg: 'Thank you for your message',
  contactYou: 'We will contact you as soon as possible',
  must: 'must',
  leaveMsg:
    'Please leave a message here and we will contact you as soon as possible.',
  enterName: 'Please enter your name',
  enterPhone: 'Please enter phone number',
  enterEmail: 'Please enter email address',
  confirm: 'Confirm',

  // 下面是首页的英文
  ImplementationCase: 'IMPLEMENTATION CASE',
  contentUs: 'CONTACT US',
  newsEn: 'NEWS',
  SolutionsEn: 'USER SCENAEISO',
  productEn: 'PRODUCT CENTER',
  more: 'MORE',
  Telephone: 'Telephone',
  Email: 'Email',
  address: 'Address',

  // 首页的标题切换成中文
  News: 'News',
  case: '实施案例',
  Solutions: 'User Scenarios',
  product: '产品中心',
  contentUsCh: '联系我们',
  // 下面是其他页面的英文
  ServiceNetwork: 'Service Network',
  CompanyProfile: 'Company Profile',
  recruitPosition: 'Recruit Position',
  salary: 'Salary',
  place: 'Place',
  delivery: 'Delivery',
  afterSalesService: '24-hour after-sales service telephone',
  serviceEmail: 'Email',
  previousPage: 'Previous page',
  nextPage: 'Next page',
  productDescription: 'Product description',
  productFunction: 'Product function',
  release: 'release',
  RelevantNews: 'Relevant News',
  DeliverResume: 'Deliver Resume',
  RealName: 'Name：',
  sex: 'Sex：',
  fileUpload: 'File upload：',
  JobApplication: 'Job application：',
  SalaryExpectation: 'Salary：',
  TelephoneNumber: 'Telephone number：',
  sir: 'Sir',
  lady: 'Lady',
  ResumeEmail: 'Email：',
  fileUploadMsg: 'Drag the file here, or click upload',

  // 产品中心
  productSuperiority: 'Product Superiority',
  productList: 'Product List',
  productCenter:'Product Center'
}
