// let baseUrl = 'https://oa.gosionrobot.com';
let baseUrl = '';
// http://robotapi.yingliyun.com
// 生产环境
// http://frp.rngay.cn
if (process.env.NODE_ENV === 'development' ) {
  baseUrl = '/api';
  // baseUrl = 'https://oa.gosionrobot.com:443';
} else{
  baseUrl = 'https://oa.gosionrobot.com';
}
// 设置axios总信息
let config = {
  method: 'get',
  // 基础url前缀
  baseUrl: baseUrl,
  // 请求头信息,
  header: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  // 参数
  // data是作为请求主体被发送的数据
  data: {},
  // 设置超时时间
  // 如果请求超过 `timeout` 的时间，请求将被中断
  timeout: 10000,
  // 携带凭证,表示跨域请求时是否需要使用凭证
  withCredentials: true,
  // 返回数据类型
  responseType: 'json'
};

export default config
