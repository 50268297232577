import Vue from 'vue';
import 'jquery/dist/jquery.min';
import "popper.js/dist/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'
Vue.use(animated)

// 按需导入ui组件
import {
    Container,
    Card,
    Main,
    Footer,
    Aside,
    Header,
    Loading,
    Col,
    Row,
    Button,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Pagination,
    Carousel,
    CarouselItem,
    Table,
    TableColumn,
    Collapse,
    CollapseItem,
    Dialog,
    Form,
    FormItem,
    Upload,
    Radio,
    RadioGroup,
} from 'element-ui';

Vue.use(Container)
   .use(Card)
   .use(Main)
   .use(Footer)
   .use(Aside)
   .use(Header)
   .use(Col)
   .use(Row)
   .use(Button)
   .use(Input)
   .use(Breadcrumb)
   .use(BreadcrumbItem)
   .use(Pagination)
   .use(Carousel)
   .use(CarouselItem)
   .use(Table)
   .use(TableColumn)
   .use(Collapse)
   .use(CollapseItem)
   .use(Dialog)
   .use(Form)
   .use(FormItem)
   .use(Upload)
   .use(Radio)
   .use(RadioGroup);

   Vue.prototype.$loading = Loading.service;