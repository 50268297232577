export default {
    Home:"首页",
    productCenter:"产品中心",
    IndustrySolutions:"用户案例",
    newsCenter:"新闻资讯",
    service:"服务中心",
    aboutUs:"关于国巡",
    joinUs:"加入我们",   
    MessageBoard:"留言板",
    thankMsg:"感谢留言",
    contactYou:"我们会尽快与您联系",
    must:"必选项",
    leaveMsg:"请在此留言内容，我们会尽快与您联系。",
    enterName:"请输入姓名",
    enterPhone:"请输入电话",
    enterEmail:"请输入邮箱",
    confirm:"确 定",

    // 下面是首页的中文
    ImplementationCase:"实施案例",
    contentUs:"联系我们",
    newsEn:"新闻中心",
    SolutionsEn:"用户案例",
    productEn:"产品中心", 
    more:"查看更多",
    moreNews:"查看更多资讯",
    Telephone:"售后/投诉热线",
    Email:"邮箱",
    address:"地址",


    // 首页的标题切换成英文
    News:"新闻资讯",
    case:"CASE",
    Solutions:"用户案例",
    product:"PRODUCT CENTER",
    contentUsCh:"CONTACT US",

    // 下面是其他页面的中文    
    ServiceNetwork:"服务网点",
    CompanyProfile:"公司简介",
    recruitPosition:"职位",
    salary:"薪资",
    place:"地点",
    delivery:"投递",
    afterSalesService:"24小时售后服务电话",
    serviceEmail:"服务邮箱",
    previousPage:"上一页",
    nextPage:"下一页",
    productDescription:"产品描述",
    productFunction:"产品功能",
    release:"发布",
    RelevantNews:"相关新闻",
    DeliverResume:"投递简历",
    RealName:"真实姓名：",
    sex:"性别：",
    fileUpload:"附件简历：",
    JobApplication:"应聘岗位：",
    SalaryExpectation:"期望薪资：",
    TelephoneNumber:"电话号码：",
    sir:"先生",
    lady:"女士",
    ResumeEmail:"邮箱：",
    fileUploadMsg:"将文件拖到此处,或点击上传",

    // 产品中心
    productSuperiority:'产品优势',
    productList:'产品列表',
    productCenter:'产品中心'

}